















import Vue from "vue";
import { catApi, catVue } from "@/util/logging";
import { api } from "@/util/api";

export default Vue.extend({
  // Component name for reference
  name: "BarcodeButton",

  created() {
    catVue.info(`${this.$options.name}:: CREATED... `);
  },
  mounted() {
    catVue.info(
      `${this.$options.name}:: MOUNTED... EAN -> ${this.Ean} | Path -> ${this.Path}`
    );
  },
  props: {
    Ean: String,
    Path: String,
    Filiale: Number,
  },
  //
  methods: {
    //
    async getLabel(): Promise<void> {
      // huhu
      const url = `/v1${this.Path}/label?filiale=${this.Filiale}`;
      try {
        const response = await api.get(url, { responseType: "blob" });
        //           catApi.debug(`response: ${url} -> ${JSON.stringify(response)}`);
        if ("data" in response) {
          var fileURL = window.URL.createObjectURL(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            new Blob([response.data as any])
          );
          var fURL = document.createElement("a");

          fURL.href = fileURL;
          var filename = response.headers["x-lbl-filename"];
          if (filename === undefined) filename = "label.pdf";

          fURL.setAttribute("download", filename);
          document.body.appendChild(fURL);

          fURL.click();
          this.$nextTick(() => document.body.removeChild(fURL));
        }
      } catch (err) {
        catApi.warn("BarcodeButton API -> Exception: " + err);
      }
    },
  },
  //
});
